.heading-contact-us {
  font-family: var(--font-heading);
  font-weight: 700;
  font-size: 5.5 rem;
  color: var(--color-primary);
  margin: 3rem auto;
}

.heading-small-contact-us {
  align-self: center;
  justify-self: center;
  font-family: var(--font-primary);
  color: var(--color-primary);
  font-size: 2.6rem;
}

.contact-us-form-container {
  padding: 2rem 4rem;
}

.contact-us-section {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  padding: 3rem 5rem;
}

.contact-icon {
  font-size: 3rem;
  transform: translateY(15px);
  margin-top: 10px;
}

.contact-us-form-button {
  display: block;
  width: 12rem;
  text-align: center;
  font-family: var(--font-heading);
  font-weight: 700;
  color: var(--color-white);
  padding: 1rem 2rem;
  margin-top: 2rem;
  background-color: var(--color-primary);
  transition: all 0.5s;
}

.desc-auv {
  color: var(--color-primary-light);
  font-weight: 500;
  font-size: 2rem;
}
