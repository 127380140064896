.accordian-section {
	margin-bottom: 20px;
	width: 100%;
	box-shadow: 0px 2px 15px rgba(53, 35, 35, 0.2);
}

.accordian-toggle {
	background-color: var(--color-tertiary);
	/* max-height: 150px; */
	min-height: 380px;
	padding: 10px;
	font-size: 2rem;
	font-weight: 700;
	font-family: var(--font-heading);
	color: var(--color-black);
	margin: 1.5rem;
	border-radius: 3%;
}

.accordian-toggle:hover {
	transition: all 0.4s ease-in-out;
	color: #fff;
}

/* .hide {
	display: block;
	text-align: left;
	margin: 10px;
	font-family: var(--font-primary);
}

.redirect {
	font-family: var(--font-primary);
	text-decoration: underline;
	font-weight: 900;
}

@media only screen and (max-width: 900px) {
	.hide {
		display: none;
	}
	.accordian-toggle {
		min-height: auto;
	}
}

.subsystem-section {
	padding-top: 40px;
}

.cardbody {
	padding: 10px;
}

.member-img {
	width: 100%;
}

.card-background {
	border-radius: 1rem;
	width: 100%;
	padding: 20px;
	margin-bottom: 20px;
	box-shadow: 5px 5px 20px rgba(12, 12, 12, 0.4);
	background-color: var(--color-white);
}

.subsystem-text {
	font-size: 16px;
	font-family: var(--font-primary);
	font-weight: 600;
	color: var(--color-primary);
}

.team {
	background: #fff;
	padding: 20px 20px 20px 20px;
	align-items: center;
}

.member {
	align-items: center;
}

.team .member {
	border-radius: 0%;
	margin-bottom: 0px;
	overflow: hidden;
	background: #fff;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
	position: relative;
	overflow: hidden;
	border-radius: 0%;
}

.team .member .social {
	position: absolute;
	left: 0;
	bottom: 30px;
	right: 0;
	opacity: 0;
	transition: ease-in-out 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
}

.team .member .social a {
	transition: color 0.3s;
	margin: 0 3px;
	border-radius: 50px;
	width: 36px;
	height: 36px;
	background: var(--color-tertiary);
	transition: ease-in-out 0.3s;
	color: #fff;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.team .member .social a:hover {
	background: var(--color-quartenary);
}

.team .member .social i {
	font-size: 18px;
	line-height: 0;
}

.team .member .member-info {
	padding-bottom: 10px;
}

.team .member .member-info h4 {
	font-weight: 700;
	margin-bottom: 5px;
	font-size: 18px;
	color: var(--color-black);
}

.team .member .member-info span {
	display: block;
	font-size: 15px;
	font-weight: 500;
	color: var(--color-black);
}

.team .member:hover .social {
	opacity: 1;
	bottom: 15px;
} */

.center {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin-bottom: 20px;
}

.subSHeading {
	font-size: 32px;
	font-weight: 500;
	letter-spacing: -0.75px;

	text-transform: uppercase;
}

.icon {
	size: 30px;
	color: red;
}

.link {
	margin-top: 20px;
	color: #000;
	padding: 5px 10px;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	display: inline-block;
	text-align: center;
	background-color: #bdc8d3;

	transition: 0.3s all;
}

.link:hover {
	text-decoration: underline;
	transform: translateY(-3px);
}

.link:active {
	transform: translateY(-1px);
}

.info-link {
	display: flex;
	flex-direction: column;
}

.info {
	font-size: 16px;
	color: #333;
	font-weight: 400;
}

.last-of-flex {
	margin-top: auto;
}

.heading-secondary {
	text-align: center;
	font-size: 50px;
	font-weight: 600;
	letter-spacing: -0.75px;
	margin-top: 100px;
	margin-bottom: -50px;
}
