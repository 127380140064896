.iframe-container {
  overflow: hidden;
  padding-top: 56.25%; /* 16:9*/
  position: relative;
  box-shadow: 0px 10px 15px rgba(53, 35, 35, 0.3);
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
/* .yt-video{
    width: 100%;
    height: 60rem;
    box-shadow: 2px 5px 20px #555;
    margin-top: 4rem;
} */
