.footer-new {
  background-color: rgb(34, 70, 109);
  width: 100%;
  margin: 0px;
  padding-bottom: 20px;
}

.footer-heading {
  width: 100%;
  margin: 0;
  padding: 0;
}

.logo-description {
  padding: 40px 10px;
}

.footer-row {
  margin-right: 0;
}

.footer-heading-primary {
  font-family: var(--font-heading);
  font-weight: 500;
  color: white;
  font-size: 2.3rem;
}

.footer-address {
  font-family: var(--font-primary);
  color: white;
  font-size: 14px;
}

.quick-social-links {
  padding: 40px 20px;
}

.footer-links-heading {
  color: var(--color-tertiary);
  font-size: 17px;
  font-weight: 500;
}

.footer-nav-link {
  color: white !important;
  font-size: 12px;
  padding: 2px;
  font-weight: 500;
}

.footer-nav-link:hover {
  color: var(--color-tertiary) !important;
  transition: all 0.3s ease-in;
}

.footer-nav-icon {
  margin: 0.5rem 1rem;
  text-align: center;
  padding: 0.5rem;
  font-family: var(--font-primary);
  color: white !important;
  font-size: 3rem;
  font-weight: 400;
}

.footer-nav-icon > i {
  color: #ffffff;
}

.footer-nav-icon > i:hover {
  color: var(--color-tertiary);
  transition: all 0.3s ease-in;
}

.nav-icons-container {
  display: flex;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 650px) {
  .footer-nav-link {
    display: none;
  }
  .footer-links-heading {
    display: none;
  }

  .nav-icons-container {
    display: flex;
    justify-content: space-around;
  }
  .footer-heading-primary {
    font-size: 1.8rem;
  }
  .footer-address {
    font-size: 12px;
  }
}
