.blog-card{
    /* border: 1rem solid black; */
    box-shadow: -5px 5px 10px rgba(0,0,0,0.2);
    width: 60%;
    margin: 8rem auto;
}
.blog-content{
    margin: 2rem 8rem ;
}
.blog-heading{
    font-family: var(--font-heading);
    font-weight: 700;
    font-size: 5.5 rem;
    color: var(--color-primary);
}
.blog-heading-single-page{
    font-family: var(--font-heading);
    font-weight: 700;
    font-size: 5.5 rem;
    max-width: 80%;
    margin: 7rem auto;
    margin-bottom: 2rem;
    color: var(--color-primary);
}
.subheading-container{
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 2rem;
}

.blog-author{
    font-family: var(--font-primary);
    color: var(--color-primary);
}
.blog-author:before{
    content: '~';
}
.blog-banner-image{
    width: 100%;
    /* box-shadow: 0 0 20px rgba(0,0,0,0.3); */
    /* border: 1px solid grey; */
}
.blog-date{
    font-family: var(--font-primary);
    color: var(--color-primary);
    font-size: 2rem;
}

.blogs-description{
    align-self: center;
    justify-self: center;
    font-family: var(--font-primary);
    color: var(--color-primary);
    font-size: 2rem;
}

.blogs-more-button{
    display: block;
    width: 20rem;
    text-align: center;
    font-family: var(--font-heading);
    font-weight: 700;
    color: var(--color-white) !important;
    padding: 1rem 2rem ;
    margin-top: 2rem;
    background-color: var(--color-primary);
    transition: all 0.5s;
}

.blogs-more-button:hover{
    background-color: var(--color-primary-light);
    color: var(--color-white);
    transform: translateY(-3px);
}

@media screen and (max-width : 1600px) {
    .blog-card{
        width: 70%;
    }
}

@media screen and (max-width : 1400px ) {
    .blog-content{
        margin: 2rem 4rem;
    }
    .blogs-description{
        font-size: 18px;
    }
}
@media screen and (max-width : 1300px) {
    .blog-card{
        width: 80%;
    }
}

@media screen and (max-width: 1000px) {
    .blogs-description{
        margin-top: 3rem;
    }
}
@media screen and (max-width: 600px) {
    .blog-card{
        width: 95%;
    }
    .subheading-container{
        flex-direction: column;
        margin-top: 1rem;
    }
}