.col-main {
  display: flex;
  flex-direction: row;
}

.title-block {
  padding: 5px;
}

.heading-component {
  font-family: var(--font-heading);
  color: var(--color-primary);
}

.heading-component h2 {
  font-weight: 700;
}

.heading-component h3 {
  font-weight: 400;
  font-size: 5rem;
}

.imgCol {
  padding-top: 10px;
}

.featureCol {
  padding: 10px 45px 10px 45px;
}

.brief{
  font-size: 1.5rem;
}

.heading {
  padding-top: 0px;
  font-weight: 500;
  font-size: 32px;
}

.tab-content {
  background: white;
  height: 100%;
}

.cad-model {
  width: 100%;
  /* border: 1px solid black; */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  height: 600px;
  margin-bottom: 3rem;
}

.tdr-button {
  display: block;
  position: relative;
  float: left;
  width: 100px;
  padding: 0;
  margin: 10px 10px 10px 0;
  font-weight: 600;
  text-align: center;
  line-height: 30px;
  color: darkslategrey;
  border-radius: 5px;
  transition: all 0.2s;
  background: lightgreen;
}

.tdr-button:hover {
  background: #21825B;
  color: white;
}