.team{
    z-index: 10;
}

.sponsors{
    z-index: -1;
}

.abc{
    background-color: wheat;
}
 
.mobile-responsive{
    width: 100%;
    margin: 0 auto;
}