.navbar {
  width: 100%;
  height: 5rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(34, 70, 109);
  transition: all 0.2s ease;
  z-index: 100;
}
@media (max-width: 992px) {
  .custom-navbar-auv {
    margin: 0 !important;
    width: 100%;
  }
}

.u-margin-zero {
  margin: 0 !important;
}

.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  background-color: lightblue;
  z-index: 100;
}
.logo img {
  width: 50px;
}

.navbar-content {
  font-family: var(--font-primary);
  font-size: 1.4rem !important;
  font-weight: 900 !important;
  letter-spacing: 0.08em;
  color: #ffffff !important;
  text-align: center;
}

@media (max-width: 1200px) {
  .navbar-content {
    font-size: 1.3rem !important;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.dropdown-item:hover {
  background-color: var(--color-quartenary) !important;
  border-radius: 0px !important;
  text-align: center !important;
}

.minilogo {
  max-width: 9rem;
  margin-left: 0px;
}

@media (max-width: 576px) {
  .minilogo {
    max-width: 8rem;
  }
}

.active {
  color: black !important;
  background-color: var(--color-tertiary);
}

.navbar-content:hover {
  background-color: var(--color-quartenary);
  /* transform: translateY(3px); */
}

.navbar .navbar-collapse::after {
  background-color: rgba(2, 41, 80, 1);
}

.navbar-collapse > .nav-item > .nav-link {
  color: #ffffff !important;
}

.auv-dropdown {
  background-color: var(--color-primary-light);
  border-radius: 0 !important;
  color: #ffffff !important;
}

.dropdown-item {
  border-radius: 0;
}

.nav-social {
  color: #ffffff;
  text-align: center;
  font-size: 2.3rem !important;
}

.nav-social:hover {
  color: var(--color-tertiary);
  transition: all 0.25s ease-in;
}
.navbar-collapse .navbar-nav > .nav-item > .nav-link {
  margin: 0px 0px;
  color: #ffffff !important;
}

.navbar:not([class*="bg"]) .navbar-toggler .navbar-toggler-bar {
  background-color: #ffffff !important;
}

@media (min-width: 1200px) {
  .navbar .navbar-nav .nav-item .nav-link {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.triton-image {
  box-shadow: 0px 10px 15px rgba(53, 35, 35, 0.3);
  margin-bottom: 15px;
}
