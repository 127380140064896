.member-image {
  width: 100%;
  max-width: 400px;
  max-height: 320px;
}

.alignment {
  align-items: center;
}

.team-data {
  padding: 20px;
}

.description > p {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-black);
  font-family: var(--font-primary);
}
