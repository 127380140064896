.header-background {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), black);
  display: relative;
}

.header-styling {
  /* font-size: 62.5%; */
  background-image: url(../../../../assets/img/backgrounds/hdback.jpg);
  opacity: 0.5;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  max-width: 100%;
}

.banner-content-container {
  position: absolute;
  width: 100rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // border: 1px solid yellow;
  display: flex;
  flex-direction: column;
}
.banner-auv-logo {
  width: 40%;
  max-width: 30rem;
  align-self: center;
  margin-bottom: 3rem;
  // margin: 0 auto !important;
  // border: 1rem solid pink;
  animation-name: moveInTop;
  animation-delay: 1s;
  animation-duration: 0.5s;
  animation-fill-mode: backwards;
}

.banner-heading {
  font-family: var(--font-heading);
  font-weight: 700;
  color: white;
  opacity: 0.9;
  text-align: center;
  text-shadow: 2px 3px 10px rgba(0, 0, 0, 0.6);
  font-size: 8.5rem;
  animation-name: moveInTop;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-fill-mode: backwards;
}

.banner-subheading {
  font-family: var(--font-heading);
  font-weight: 400;
  color: white;
  opacity: 0.8;
  font-size: 3rem;
  text-align: center;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 3.2s;
  animation-fill-mode: backwards;
}
.triton-container {
  position: absolute;
  top: 20rem;
  left: -38rem;
  width: 60rem;
  height: 30rem;
}

.triton-banner {
  position: absolute;
  z-index: 100;
  width: 100%;
  opacity: 80%;
  animation-name: appearFromTop;
  animation-duration: 2s;
}

.triton-light {
  position: absolute;
  display: none;
  width: 100%;
  top: -32rem;
  right: -48rem;
  opacity: 0.1;
  -webkit-transform: rotate(-110deg);
  -moz-transform: rotate(-110deg);
  -ms-transform: rotate(-110deg);
  -o-transform: rotate(-110deg);
  transform: rotate(-110deg);
  animation-delay: 2s;
  animation-name: lightAppear;
  animation-duration: 1s;
  animation-fill-mode: backwards;
}

.banner-dive-button {
  border: none;
  display: block;
  text-align: center;
  margin: 2rem auto;
  margin-top: 4rem;
  padding: 1rem 4rem;
  font-family: var(--font-primary);
  font-weight: 400;
  color: #152238;
  color: var(--color-primary);
  font-size: 2rem;
  background-color: rgb(221, 223, 255);
  transition: all 0.3s;
  border-radius: 20px;
  animation-name: moveInBottom;
  animation-delay: 2s;
  animation-fill-mode: backwards;
  animation-duration: 1s;
  z-index: 1000;
}

.banner-dive-button:focus {
  outline: none;
}

.banner-dive-button:hover {
  // background-color: #152238;
  // color: aliceblue;
  transform: translateY(-5px);
  transform: scale(1.05);
}

// Media Queries

@media screen and (max-width: 1600px) {
  .triton-container {
    width: 40rem;
    left: -20rem;
  }

  .triton-light {
    right: -33rem;
    top: -22rem;
  }
}

@media screen and (max-width: 1300px) {
  .triton-container {
    display: none;
    left: -40rem;
  }
}

@media screen and (max-width: 1050px) {
  .banner-content-container {
    width: 80rem;
  }
}

@media screen and (max-width: 850px) {
  .banner-content-container {
    width: 60rem;
  }

  .banner-auv-logo {
    width: 35%;
  }
  .banner-heading {
    font-size: 6rem;
  }

  .banner-subheading {
    font-size: 2.5rem;
  }

  .banner-dive-button {
    padding: 0.5rem 3rem;
  }
}
@media screen and (max-width: 767px) {
  .banner-content-container {
    top: 50vh;
    z-index: 99;
  }
}

@media screen and (max-width: 630px) {
  .banner-content-container {
    width: 40rem;
  }

  .banner-heading {
    font-size: 5rem;
  }
}

@media screen and (max-width: 400px) {
  .banner-content-container{
    width: 30rem;
    top: 50vh;
  }

  .banner-heading {
    font-size: 4rem;
  }

  .banner-subheading{
    font-size: 2rem;
  }
}

// Animations
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes appearFromTop {
  0% {
    opacity: 100%;
    transform: translateY(40rem);
  }

  70% {
    transform: translateY(-5rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
@keyframes moveInTop {
  0% {
    transform: translateY(-20rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes lightAppear {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 40%;
  }

  60% {
    opacity: 0%;
  }

  100% {
    opacity: 40%;
  }
}
