.tab-body {
  background-color: white;
  box-shadow: 0px 10px 15px rgba(53, 35, 35, 0.3);
  padding: 20px;
}

.tab-heading {
  font-family: var(--font-heading);
  font-weight: 500;
  font-size: 3rem;
}

.tab-content {
  font-family: var(--font-primary);
  font-size: 1.7rem;
}

.tab-image {
  box-shadow: 0px 10px 15px rgba(53, 35, 35, 0.3);
  width: 100%;
  max-width: 500px;
}

.nav-tabs > a {
  background-color: var(--color-tertiary);
  font-family: var(--font-primary);
  font-size: 1.3rem;
  margin: 2px;
}

.nav-tabs > a:first-child {
  margin-left: 0px;
}
