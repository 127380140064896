.margin-neg{
    margin-top: -2rem !important;
}

.responsive-achievement-image{
    background-color: yellow !important;
    width: 100% !important;
    margin-bottom: 0 !important;     
}

.u-margin-top-negative{
    margin-top: 1.5rem;
}

@media(max-width: 1200px){
    .responsive-achievement-image2{
        /* width: 50% !important; */
        margin-top: 1rem !important;
    }
}

@media(max-width: 768px){
    .responsive-achievement-image3{
        width: 80% !important;
    }
}

@media(max-width: 768px){
    .mobile-margin-top{
        margin-top: 0;
    }
}