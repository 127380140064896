.heading-main {
  font-family: var(--font-heading);
  font-size: 4rem;
  font-weight: 700;
  animation: ease-in 1s;
  position: relative;
  margin: 2rem auto;
  margin-bottom: 6rem;
  color: var(--color-primary);
}

.small-heading {
  font-family: var(--font-heading);
  font-weight: 500;
  line-height: 1.35;
  position: relative;
  font-size: 3rem;
  text-transform: none;
  letter-spacing: 2px;
  color: rgb(61, 61, 61);
}

.small-heading-edited {
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.35;
  position: relative;
  font-size: 1.4rem;
  text-transform: none;
  letter-spacing: 2px;
}

.image-anahita {
  width: 100%;
  margin: 4.5rem 1rem;
}
.auv-description-primary {
  font-family: var(--font-primary);
  color: var(--color-primary);
  font-size: 18px;
  text-align: left;
  line-height: 2.7rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  letter-spacing: normal;
  font-weight: 500;
}

.reduce-margin {
  margin-bottom: -15rem;
}

.btn-blue {
  color: rgba(20, 150, 180);
  background-color: white;
  border: 2px solid rgba(20, 150, 180, 0.67);
  font-family: var(--font-primary);
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  padding: 0.5rem 3rem;
  transition: linear 0.3s;
  cursor: pointer;
}

.btn-blue:hover {
  background-color: rgba(20, 150, 180);
  color: white;
}

.button-auv:hover::after {
  transform: scaleX(1.4) scaleY(1.7);
  opacity: 0;
}

.single-icon-container {
  /* background-color: orange; */
  margin-left: 2rem;
}
.icon-auv {
  position: relative;
  width: 25rem;
  /* height: 15rem; */
  font-weight: 900;
  text-decoration: bold;
  border: 2px solid rgba(20, 20, 20, 0.2);
  margin-bottom: 2rem;
  padding: 2rem 2rem;
  box-shadow: 0px 2px 3px rgba(20, 20, 20, 0.3);
  transition: all 0.3s;
  cursor: arrow;
  opacity: 0.8;
}

.icon-auv:hover {
  transform: translateY(-10px);
}

.icon-auv:hover .icon-info {
  transform: scale(1.1);
}
.icon-auv:hover .info-title {
  transform: scale(1.1);
}

.icon-info {
  color: rgba(20, 150, 180, 1);
  font-weight: 800;
  font-size: 3rem;
  font-family: var(--font-primary);
  text-decoration: bold;
}

.icons-row {
  margin-bottom: 190px;
  /* background-color: yellow; */
  text-align: center;
}

.info-title {
  font-family: var(--font-primary);
  text-transform: uppercase;
  text-decoration: bold;
  color: rgba(20, 150, 180, 1);
  letter-spacing: 2px;
  text-align: center;
  font-size: 2rem;
  padding-left: auto;
  padding-right: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.u-margin-bottom {
  border-bottom: 3px solid #3d3d3d;
  border-radius: 1rem;
}

.icon-align {
  /* background-color: yellow; */
  margin-left: -6rem;
  margin-top: 2rem;
}

@media screen and (max-width: 1199px) {
  .icons-container {
    display: flex;
    justify-content: space-between;
  }

  .icon-auv {
    padding: 4rem 2rem;
  }

  .icon-responsive {
    font-size: 4rem;
  }
}

@media screen and (max-width: 992px) {
  .icons-container {
    background-color: transparent;
    width: 100%;
    background-color: red;
    display: flex;
    flex-wrap: wrap;
  }

  .single-icon-container {
    display: inline-block;
    /* background-color: yellow; */
    width: 40%;
  }
}

@media screen and (max-width: 600px) {
  .icon-auv {
    width: 15rem;
    height: 15rem;
  }

  .icon-info {
    color: rgba(20, 150, 180, 0.67);
    font-weight: 800;
    font-size: 2rem;
    font-family: var(--font-primary);
    text-decoration: bold;
  }

  .info-title {
    font-family: var(--font-primary);
    text-transform: uppercase;
    text-decoration: bold;
    color: rgba(20, 150, 180, 0.67);
    letter-spacing: 2px;
    text-align: center;
    font-size: 1.5rem;
    padding-left: auto;
    padding-right: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.icon-infos {
  font-family: var(--font-primary);
  margin-top: 2.44rem !important;
  font-weight: 400;
}

.all-icons-container {
  /* background-color: #3d3d3d; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .all-icons-container {
    /* background-color: green; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .all-icons-container {
    /* background-color: green; */
    display: block;
    margin-top: 3rem;
    width: 100%;
  }

  .single-icon-container {
    display: inline-block;
  }
}

.icon-responsive {
  /* background-color: yellow; */
  width: 100%;
}

@media (max-width: 1200px) {
  .icon-responsive {
    font-size: 6rem;
  }
  .icon-auv {
    /* background-color: red; */
    width: 20rem;
  }
}

@media (max-width: 992px) {
  .description2 {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .icon-auv {
    /* background-color: red; */
    width: 18rem;
  }
}

@media (max-width: 767px) {
  .heading-main {
    font-size: 2.5rem;
  }

  .small-heading {
    font-size: 2rem;
  }

  .auv-description-primary {
    font-size: 1.2rem;
  }

  .single-icon-container {
    margin: 1rem;
    /* background-color: #3d3d3d; */
    padding: 0;
    margin: 0;
  }

  .all-icons-container {
    /* background-color: darkgreen; */
    text-align: center;
  }
}
@media (max-width: 600px) {
  .icon-auv {
    width: 16rem;
    /* background-color: aqua; */
    margin: 1rem;
    padding: 2rem;
  }
}
@media screen and (max-width: 460px) {
  .icon-auv{
    height: 15rem;
  }  
}
@media (max-width: 430px) {
  .icon-auv {
    width: 14rem;
    /* background-color: aqua; */
    margin: 1rem;
  }

  .single-icon-container{
    height: 17rem;
  }
}
@media (max-width: 380px) {
  .icon-auv {
    /* background-color: red; */
    width: 12rem;
  }

  .description2 {
    font-size: 0.8rem;
  }

  .single-icon-container {
    margin: 1rem;
  }
}
