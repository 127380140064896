.light-auv{
    background: linear-gradient(to bottom, rgb(255,255,255), rgb(255, 255, 255));
}



.img-responsive1{
    background-color: white;
    width: 90%;
    /* box-shadow: 2px 2px 20px black; */
    /* padding: 30px; */ 
}

@media(max-width: 767px){
    .img-responsive1{
        /* background-color: aquamarine; */
        width: 60%;
        margin: 0 auto;
    }

    .top-margin-reduce{
        margin-top: -6rem;
        /* background-color: blueviolet; */
    }
}

.img-responsive1:hover{
    /* transform: translateY(4px); */
    /* transform: scale(1.2); */
    cursor: pointer;
    
}

@keyframes moveInLeft {
    0%{
        opacity: 0;
        transform: translateX(-10rem);
    }
    80%{
        transform: translateX(1rem);
    }
    100%{
        opacity: 1;
        transform: translate(0);
    }
} 