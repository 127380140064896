* {
  font-family: var(--font-primary);
}

.imgCol {
  padding-top: 20px;
  padding-bottom: 20px;
}

.featureCol {
  padding: 20px 35px 20px 35px;
}

.spec-card {
  background: #ADD8E6;
  transition: none !important;
}

.spec-card:hover {
  transition: none !important;
  animation: none !important;
  transform: none !important;
  cursor: pointer;
}
.subsystem-headings {
  border-bottom: 2px solid var(--color-primary);

}

.heading-components b {
  font-family: var(--font-heading);
  font-weight: 700;
  font-size: 5.6rem;
  color: var(--color-primary);
}

.spec-container {
  margin-bottom: 100px;
}

.accord-head {
  background: var(--color-quartenary);
  color: var(--color-primary);
  font-weight: 900;
}

.add-icon {
  margin-right: 10px;
  opacity: 0.7;
}