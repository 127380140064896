.achievement-card {
  background-color: var(--color-tertiary);
  box-shadow: 0px 10px 15px rgba(53, 35, 35, 0.3);
  height: 95%;
}

.card-img {
  border-radius: 0%;
  padding: 20px 20px 7px 20px;
}

.card-title {
  font-size: 2rem;
  font-family: var(--font-heading);
  font-weight: 600;
  color: var(--color-primary-light);
}

.card-info {
  font-size: 14px;
  color: var(--color-primary);
  font-weight: 500;
}
