.desc-auv{
    font-family: Montserrat  ;
    color: #DDE2EC;
    font-size: 20px;
    margin-bottom: 20px;
}

.button-auv{
    background: linear-gradient(to left, rgb(2, 13, 19), rgb(19, 47, 93));
    font-family: Montserrat  ;
    color: #cccccc;
    border-radius: 10rem;
    display: inline-block;
    border: none;
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 5px 10px #cccccc;
}
 
.button-auv:hover{
    background-color:#CCCCCC;
    background: linear-gradient(to left, rgb(2, 13, 19), rgb(35, 90, 177));
    transform: translateY(3px);
    box-shadow: 0px 2px 10px #cccccc;
}

.button-auv:hover::after{
    transform: scaleX(1.4) scaleY(1.7);
    opacity: 0;
}

.max-width-80{
    max-width: 80%;
}

@media(max-width: 768px){
    .max-width-80{
        max-width: 100%;
    }
}
