.auv-description-primary {
  font-family: var(--font-primary);
  font-size: 1.7rem;
}
.vehicle-heading {
  font-family: var(--font-heading);
  font-size: 3.5rem;
}

.text-info1 {
  font-family: Montserrat;
  color: #dde2ec;
  letter-spacing: 2px;
  font-size: 50px;
  padding-bottom: 20px;
}

.img-responsive2 {
  width: 80%;
  margin-left: 50px;
  margin-right: 50px;
}
.img-responsive3 {
  width: 90%;
  margin-left: 50px;
  margin-right: 50px;
}
.para-auv {
  color: #cccccc;
  font-family: Montserrat;
  font-size: 18px;
  padding: 10px;
}

.button-auv {
  background: linear-gradient(to left, rgb(2, 13, 19), rgb(19, 47, 93));
  font-family: Montserrat;
  color: #cccccc;
  border-radius: 10rem;
  display: inline-block;
  border: none;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 5px 10px #cccccc;
}

.button-auv:hover {
  background-color: #cccccc;
  background: linear-gradient(to left, rgb(2, 13, 19), rgb(35, 90, 177));
  transform: translateY(3px);
  box-shadow: 0px 2px 10px #cccccc;
}

.button-auv:hover::after {
  transform: scaleX(1.4) scaleY(1.7);
  opacity: 0;
}

.vehicle-image {
  margin-bottom: 15px;
}

.vehicle-row {
  margin-bottom: 50px;
}
