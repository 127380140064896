.single-blog{
    /* border: 1px solid black; */
    box-shadow: -6px 6px 15px rgba(0,0,0,0.2);
    width: 80%;
    margin: 10rem auto;
    text-align: center;
    padding: 4rem 16rem;
    position: relative;
}

.single-blog-date{
    position: absolute;
    top: 5rem;
    right: 2rem;
    margin-right: 6rem;
    font-family: var(--font-primary);
    color: var(--color-primary);
    font-size: 2rem;
}

.single-blog-author{
    font-family: var(--font-primary);
    color: var(--color-primary);
    font-size: 3rem;
    margin-bottom: 2rem;
}

.single-blog-author::before{
    content: "~by "
}

.blog-secondary-heading{
    font-family: var(--font-heading);
    font-weight: 600;
    color: var(--color-primary);
    font-size: 3.5rem;
    
}

.blog-list-block{
    text-align: left;
}

.blog-list-heading{
    font-family: var(--font-primary);
    color: var(--color-primary);
}

.blog-list-item{
    font-family: var(--font-primary);
    color: var(--color-primary);
    font-size: 2rem;
    list-style: square;
}
.single-blog-image{
    width: 60%;
    margin: 2rem auto;
    max-width: 600px;
    /* border: 1px solid var(--color-primary); */
}
@media screen and (max-width: 1500px){
    .single-blog-date{
        position: relative;
        top: 0rem;
        right: 0rem;
        margin-right: 0rem;
        font-family: var(--font-primary);
        color: var(--color-primary);
        font-size: 2rem;
        margin-bottom: 4rem;
    }

    .single-blog{
        padding: 4rem 10rem;
    }
}

@media screen and (max-width: 1000px){
    .single-blog{
        padding: 4rem 4rem;
    }

    .single-blog-image{
        width: 80%;
    }
}

@media screen and (max-width: 600px){
    .single-blog{
        padding: 4rem 2rem;
    }

    .single-blog-image{
        width: 95%;
    }
}
