.legacy-section {
  padding-bottom: 50px;
}

.carousel-container {
  padding-bottom: 30px;
}

.carousel-item {
  display: flex;
  padding: 20px;
  height: 100%;
}

.legacy-cards {
  border-radius: 1.5rem;
  box-shadow: 0px 5px 12px rgba(37, 32, 32, 0.4);
  height: 100%;
  max-height: 500px;
}

.legacy-images {
  max-width: 300px;
  max-height: 300px;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 35rem;
}

.legacy-name {
  color: var(--color-primary);
  font-family: var(--font-heading);
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 2px;
}

.legacy-position {
  color: var(--color-primary);
  font-family: var(--font-primary);
  margin-bottom: 2px;
  font-size: 1.5rem;
}

.legacy-social:hover {
  color: var(--color-quartenary) !important;
  transition: all 0.3s ease-in-out;
}

.rec.rec-arrow {
  border-radius: 50px;
  transform: scale(0.6);
}

@media only screen and (max-width: 1200px) {
  .legacy-cards {
    min-height: 425px;
  }
}

@media only screen and (max-width: 990px) {
  .legacy-cards {
    min-height: 435px;
  }
}

@media only screen and (max-width: 768px) {
  .legacy-cards {
    min-height: 365px;
  }
}
